


import Tooltip from '@/components/Tooltip/Tooltip.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Alert as AlertType } from '@/models/type'

@Component({
  components: {
    Tooltip
  }
})
export default class Alert extends Vue {
  get alerts (): AlertType[] { return this.$store.getters.alerts }
}
