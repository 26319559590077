


import Tooltip from '@/components/Tooltip/Tooltip.vue'
import Excel from './Excel'
import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { Errors } from '@/models/type'

@Component({
  components: {
    Tooltip
  }
})
export default class SubmitView extends Vue {
  private signatureValid = false
  private sentSuccessfully = false
  private sending = false
  private momentOfSubmission: { date?: string, time?: string } = {}

  // private dataError = false

  private dataErrorMessage = ''
  private checkConfirmSend = false
  private checkConfirmCorrections = false

  private dataErrorMessageError = false
  private checkConfirmSendError = false
  private checkConfirmCorrectionsError = false

  get errors (): Errors { return this.$store.getters.errors }
  get dataError () : boolean { return this.errors.counters === true || this.errors.receipts === true || this.errors.commissions === true }
  // private dataError = true

  created (): void {
    if (this.$store.getters.dataRequest.status === 2 || this.$store.getters.dataRequest.status === 3 || this.$store.getters.dataRequest.status === 4 || this.$store.getters.dataRequest.status === 409) {
      this.sentSuccessfully = true
    }
    // this.checkData()
    // console.log(this.$store.getters.dataRequest)
  }

  toggleDataError (error: boolean): void {
    if (error) {
      const alert = {
        type: 'error',
        message: ['Bitte prüfen Sie Ihre Eingaben. Bei Problemen wenden Sie sich an den Support.'],
        button: 'Support kontaktieren',
        disableClosing: true,
        src: { step: 5, reason: 'errorIdle' }
      }
      this.$store.dispatch('createAlert', alert)
    } else {
      const src = { step: 5, reason: 'errorIdle' }
      this.$store.dispatch('closeAlert', src)
    }
  }

  checkData (): void {
    this.toggleDataError(this.dataError)
  }

  validateSignature (event: InputEvent): void {
    const input = (event.target as HTMLInputElement).value
    if (input !== undefined) {
      if (input.match(/^[a-zA-Z]+\s+[a-zA-Z]+/)) {
        this.signatureValid = true
      } else {
        this.signatureValid = false
      }
    }
  }

  submit (): void {
    if (this.dataError === true) {
      if (this.dataErrorMessage !== '' && this.checkConfirmSend === true && this.checkConfirmCorrections === true) {
        this.send()
      } else {
        this.dataErrorMessageError = this.dataErrorMessage === ''
        this.checkConfirmSendError = this.checkConfirmSend === false
        this.checkConfirmCorrectionsError = this.checkConfirmCorrections === false
      }
    } else {
      this.send()
    }
  }

  send (): void {
    this.sending = true
    moment.locale('de')
    this.momentOfSubmission = {
      date: moment().format('DD.MM.YYYY'),
      time: moment().format('LT')
    }
    // this.sentSuccessfully = true
    // this.$emit('sentSuccessfully')
    if (this.$route.query.bo) {
      this.$store.getters.dataRequest.status = 3
    } else {
      this.$store.getters.dataRequest.status = 2
    }
    let request = this.$store.getters.dataRequest
    if (request.open === false) {
      request = {
        date: request.date,
        carwash: request.carwash,
        partnerName: request.partnerName,
        open: request.open,
        reasonForClosing: request.reasonForClosing,
        personInAuthority: request.personInAuthority,
        status: 5
      }
    }
    // TODO add logic for error data request
    if (this.dataError === true) {
      request.hasError = true
      request.partnerMessage = this.dataErrorMessage
      request.status = 409
    }

    Vue.axios.put(this.$route.path, request).then((response) => {
      this.sending = false
      if (response.status === 200) {
        this.sentSuccessfully = true
        this.$emit('sentSuccessfully')
      } else {
        const alert = {
          type: 'error',
          message: ['Bei der Übermittlung der Daten ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.'],
          button: 'Support kontaktieren',
          disableClosing: true,
          src: { step: 5, reason: 'errorRequest' }
        }
        this.$store.dispatch('createAlert', alert)
      }
    }).catch((error) => {
      console.log(error)
      this.sending = false
      const alert = {
        type: 'error',
        message: ['Bei der Übermittlung der Daten ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.'],
        button: 'Support kontaktieren',
        disableClosing: true,
        src: { step: 5, reason: 'errorRequest' }
      }
      this.$store.dispatch('createAlert', alert)
    })
    // this.axios.put(, this.$store.getters.dataRequest)
    // setTimeout(() => {
    //   this.sending = false
    //   const requestSuccessful = true
    //   if (requestSuccessful) {
    //     this.sentSuccessfully = true
    //     this.$emit('sentSuccessfully')
    //   } else {
    //     const alert = {
    //       type: 'error',
    //       message: ['Bei der Übermittlung der Daten ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.'],
    //       button: 'Support kontaktieren',
    //       disableClosing: true,
    //       src: { step: 5, reason: 'errorRequest' }
    //     }
    //     this.$store.dispatch('createAlert', alert)
    //   }
    // }, 1000)
  }

  async download (): Promise<void> {
    let downloadFailed = false
    const data = this.$store.getters.dataRequest
    try {
      const excel = new Excel(data, false, this.dataError || this.$store.getters.dataRequest.status === 409)
      excel.createExcel()
      excel.fillContent()
      excel.formatCells()
      if (data.allScans && data.allScans.length > 0) {
        excel.createExcelTransactions()
        excel.fillContentTransactions()
        excel.formatCellsTransactions()
      }
      const status = await excel.provideExcel()
      if (status === 'error') {
        downloadFailed = true
      }
    } catch (error) {
      console.log(error)
      downloadFailed = true
    }
    if (downloadFailed) {
      const alert = {
        type: 'error',
        message: ['Die Abrechnung konnte nicht heruntergeladen werden. Bitte wenden Sie sich an den Support.'],
        button: 'Support kontaktieren',
        disableClosing: true,
        src: { step: 5, reason: 'errorDownload' }
      }
      this.$store.dispatch('createAlert', alert)
    }
  }

  async preview (): Promise<void> {
    let downloadFailed = false
    const data = this.$store.getters.dataRequest
    try {
      const excel = new Excel(data, true, this.dataError)
      excel.createExcel()
      excel.fillContent()
      excel.formatCells()
      if (data.allScans && data.allScans.length > 0) {
        excel.createExcelTransactions()
        excel.fillContentTransactions()
        excel.formatCellsTransactions()
      }
      const status = await excel.provideExcel()
      if (status === 'error') {
        downloadFailed = true
      }
    } catch (error) {
      console.log(error)
      downloadFailed = true
    }
    if (downloadFailed) {
      const alert = {
        type: 'error',
        message: ['Die Abrechnung konnte nicht heruntergeladen werden. Bitte wenden Sie sich an den Support.'],
        button: 'Support kontaktieren',
        disableClosing: true,
        src: { step: 5, reason: 'errorDownload' }
      }
      this.$store.dispatch('createAlert', alert)
    }
  }
}
