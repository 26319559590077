


import Tooltip from '@/components/Tooltip/Tooltip.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Tooltip
  }
})
export default class FailView extends Vue {
   @Prop({ default: 'Beim Laden der digitalen Tagesabrechnug \n ist ein Fehler aufgetreten.' }) failViewText!: string
}
