


import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class NumberInput extends Vue {
  @Prop() private initialValue!: number | string | null
  @Prop() private hasError!: boolean
  @Prop({ default: false }) private small?: boolean

  private hasErrorTest = this.hasError

  private formattedValue = ''

  mounted (): void {
    this.formattedValue = this.initialValue === null || this.initialValue === '' ? '' : Intl.NumberFormat('de').format(Number(this.initialValue))
  }

  isNumber (event: KeyboardEvent): boolean {
    const key = event.key
    const validKey: boolean =
      (key >= '0' && key <= '9') || (key >= 'Num0' && key <= 'Num9') ||
      key === 'Backspace' ||
      key === 'Delete' ||
      key === 'Enter' ||
      key === 'Tab' ||
      ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(key)
    if (validKey) return true
    event.preventDefault()
    return false
  }

  focus (): void {
    if (this.initialValue !== null && this.initialValue !== '') {
      this.formattedValue = Number(this.initialValue).toString()
    } else {
      this.formattedValue = ''
    }
  }

  blur (): void {
    this.$emit('input', this.formattedValue === null || this.formattedValue === '' ? null : Number(this.formattedValue.replace('.', '')))
    setTimeout(() => {
      this.formattedValue = Vue.filter('formatNumber')(this.initialValue)
    }, 1)
  }

  @Watch('initialValue')
  onInitialValueChanged (): void {
    this.formattedValue = Vue.filter('formatNumber')(Number(this.initialValue))
  }
}
