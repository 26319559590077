


import Tooltip from '@/components/Tooltip/Tooltip.vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
    Tooltip
  }
})
export default class Header extends Vue {
  @Prop() private sentSuccessfully!: boolean

  dataAvailable = true

  beforeMount (): void {
    if (Object.keys(this.$store.getters.dataRequest).length === 0) {
      this.dataAvailable = false
    }
  }
}
