


import Tooltip from '@/components/Tooltip/Tooltip.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Tooltip
  }
})
export default class Footer extends Vue {
  // @Prop({ default: false }) private currentStep!: number
  @Prop({ default: false }) private nextStepEnabled!: boolean

  get currentStep (): number { return this.$store.getters.currentStep }
}
