


import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Process extends Vue {
  // @Prop() private currentStep!: number
  @Prop() private stepsDone!: Array<number>
  @Prop() private sentSuccessfully!: boolean

  get currentStep (): number { return this.$store.getters.currentStep }
}
