import Vue from 'vue'
import App from './App/App.vue'
import store from '@/store/index'
import router from '@/router/index'

import vuescroll from 'vue-scroll'
// import Alert from '@/components/Alert/Alert.vue'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import VCurrencyField from 'v-currency-field'

import moment from 'moment'

import './sass/main.scss'

import VueNumeric from 'vue-numeric'

import InlineSvg from 'vue-inline-svg'

import axios from 'axios'

import VueAxios from 'vue-axios'

Vue.component('inline-svg', InlineSvg)

Vue.prototype.moment = moment
Vue.config.productionTip = false

// Vue.component('alert', Alert)

Vue.use(VueAxios, axios)

Vue.use(vuescroll)

Vue.use(VueNumeric)

Vue.use(Vuetify)

Vue.use(VCurrencyField, {
  locale: 'de-DE',
  decimalLength: 2,
  allowNegative: false,
  autoDecimalMode: true
})

Vue.axios.defaults.baseURL = 'https://abrechnung.tool.center/'

// Vue.use(Alert)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

Vue.filter('toCurrency', function (value: string) {
  const formatter = new Intl.NumberFormat('de', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })
  return formatter.format(+value)
})

Vue.filter('toDecimal', function (value: string) {
  const formatter = new Intl.NumberFormat('de', {
    style: 'decimal',
    minimumFractionDigits: 2
  })
  return formatter.format(+value)
})

Vue.filter('toCommission', function (value: string, commissionsRelative?: boolean) {
  const formatter = new Intl.NumberFormat('de', {
    style: commissionsRelative === true ? 'percent' : 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })
  return formatter.format(commissionsRelative ? +value / 100 : +value)
})

Vue.filter('formatNumber', function (value: string | number | null | undefined) {
  if (value === '') {
    return ''
  } else if (value === null) {
    return null
  } else {
    const number = Number(value)
    return Intl.NumberFormat('de').format(number)
  }
})
