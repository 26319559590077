import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    // { path: '/', redirect: '/with-counters' },
    // { path: '/more-programs' },
    // { path: '/washstreets/:id' }
  ]
})

export default router
