


import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Dropdown extends Vue {
  @Prop({ default: [] }) private options!: string[]
  @Prop({ default: '' }) private initialValue!: string
  @Prop({ default: false }) private disabled!: boolean

  placeholder = 'Auswählen'
  selectedOption = ''
  dropdownOpen = false

  @Watch('disabled')
  onDisabledChanged (): void {
    if (this.disabled) {
      this.selectedOption = ''
    }
  }

  @Watch('initialValue')
  onInitialValueChanged (): void {
    this.selectedOption = this.initialValue
  }

  selectOption (option: string):void {
    this.selectedOption = option
    this.$emit('optionSelected', option)
    this.dropdownOpen = false
  }

  selectOptionMobile (option: InputEvent):void {
    const target = option.target as HTMLSelectElement
    this.selectedOption = target.value
    this.$emit('optionSelected', target.value)
    this.dropdownOpen = false
  }
}
