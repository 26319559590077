import Vue from 'vue'
import Vuex from 'vuex'

import { Data, Alert, Errors } from '@/models/type'

Vue.use(Vuex)

const store = new Vuex.Store<{
  dataResponse: Data | null,
  dataRequest: Data | null,
  currentStep: number,
  errors: Errors,
  alerts: Alert[],
  showModal: boolean
}>({
  state: {
    dataResponse: null,
    dataRequest: null,
    currentStep: 1,
    errors: { counters: false, receipts: false, commissions: false },
    alerts: [],
    showModal: false
  },
  mutations: {
    dataResponse (state, dataResponse) {
      state.dataResponse = dataResponse
    },
    dataRequest (state, dataRequest) {
      state.dataRequest = dataRequest
    },
    currentStep (state, currentStep) {
      state.currentStep = currentStep
    },
    errors (state, errors) {
      state.errors = errors
    },
    alerts (state, alerts) {
      state.alerts = alerts
    }
  },
  actions: {
    importData (context, data) {
      const dataResponse: Data = JSON.parse(JSON.stringify(data))
      const dataRequest: Data = JSON.parse(JSON.stringify(data))
      context.commit('dataResponse', { ...dataResponse })
      context.commit('dataRequest', { ...dataRequest })
    },
    toggleError (context, error: { step: string, value: boolean }) {
      const errors = context.getters.errors
      errors[error.step] = error.value
      context.commit('errors', errors)
    },
    createAlert (context, alertToCreate) {
      const alerts: Alert[] = context.getters.alerts
      const alert: Alert = JSON.parse(JSON.stringify(alertToCreate))
      if (alert.src.step === context.getters.currentStep) {
        let alertExists = false
        alerts.forEach((element) => {
          let equalSource = true
          Object.keys(element.src).forEach((key: string) => {
            const srcKey = key as keyof Alert['src']
            if (element.src[srcKey] !== alert.src[srcKey]) {
              equalSource = false
            }
          })
          if (equalSource) {
            alertExists = true
          }
        })
        if (!alertExists) {
          alerts.push(alert)
          context.commit('alerts', alerts)
        }
      }
    },
    closeAlert (context, src) {
      const alerts: Alert[] = context.getters.alerts
      const source = JSON.parse(JSON.stringify(src))
      alerts.forEach((element, index) => {
        let equalSource = true
        Object.keys(source).forEach((key: string) => {
          const srcKey = key as keyof Alert['src']
          if (element.src[srcKey] !== source[srcKey]) {
            equalSource = false
          }
        })
        if (equalSource) {
          alerts.splice(index, 1)
        }
      })
      context.commit('alerts', alerts)
    },
    clearAlerts (context) {
      const alerts = context.getters.alerts
      for (let index = 0; index < alerts.length; index++) {
        const element = alerts[index]
        if (element.src.step !== context.getters.currentStep) {
          alerts.splice(index, 1)
          index--
        }
      }
      context.commit('alerts', alerts)
    }
    // clearAlerts (context, step) {
    //   const alerts = context.getters.alerts
    //   for (let index = 0; index < alerts.length; index++) {
    //     const element = alerts[index]
    //     if (element.src.step !== step) {
    //       alerts.splice(index, 1)
    //       index--
    //     }
    //   }
    //   context.commit('alerts', alerts)
    // }
  },
  getters: {
    dataResponse: (state) => state.dataResponse,
    dataRequest: (state) => state.dataRequest,
    currentStep: (state) => state.currentStep,
    errors: (state) => state.errors,
    alerts: (state) => state.alerts
  }
})

export default store
