


import Tooltip from '@/components/Tooltip/Tooltip.vue'
import Dropdown from '@/components/Dropdown/Dropdown.vue'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
    Tooltip,
    Dropdown
  }
})
export default class StartView extends Vue {
  @Prop({ default: false }) nextStepWarning!: boolean

  private wasClosed = false
  private selectedReason = ''
  private reasons = ['Sonntags bzw. Feiertags geschlossen', 'Partnerwechsel', 'Technischer Defekt / Reparatur', 'Baulicher Defekt / Reparatur', 'Renovierung / Umbau', 'Sonstiges']

  mounted (): void {
    this.initData()
    const alert = {
      type: 'warn',
      message: ['Sie müssen bitte für JEDEN TAG eine Abrechnung erstellen (auch für Sonntags und/oder Feiertags). Bitte lassen Sie keinen Tag aus. Ansonsten sind weitere Abrechnungen nicht möglich und die Auszahlung verzögert sich.'],
      src: { step: 1, reason: 'startWarning' }
    }
    setTimeout(() => {
      this.$store.dispatch('createAlert', alert)
    }, 500)
  }

  initData (): void {
    const open = this.$store.getters.dataRequest.open
    if (open !== undefined) {
      this.wasClosed = !open
      if (!open) {
        const reason = this.reasons.find((option) => option === this.$store.getters.dataRequest.reasonForClosing)
        if (reason === undefined) {
          this.selectedReason = this.reasons[this.reasons.length - 1]
        } else {
          this.selectedReason = reason
        }
      }
    } else {
      this.changeStatus()
    }
    this.checkData()
  }

  changeStatus (): void {
    this.$store.state.dataRequest.open = !this.wasClosed
    if (!this.wasClosed) {
      delete this.$store.state.dataRequest.reasonForClosing
      this.selectedReason = ''
    }
    this.checkData()
  }

  changeReasonForClosing (event?: InputEvent): void {
    if (this.selectedReason !== this.reasons[this.reasons.length - 1]) {
      this.$store.state.dataRequest.reasonForClosing = this.selectedReason
    } else if (event === undefined) {
      delete this.$store.state.dataRequest.reasonForClosing
    }
    this.checkData()
  }

  checkData (): void {
    let dataComplete = false
    if (this.$store.getters.dataRequest.open) {
      dataComplete = true
    } else if (this.$store.getters.dataRequest.reasonForClosing !== undefined && this.$store.getters.dataRequest.reasonForClosing !== '') {
      dataComplete = true
    } else {
      dataComplete = false
    }
    this.$emit('dataCheck', dataComplete)
  }
}
