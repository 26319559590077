


import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Tooltip extends Vue {
  @Prop({ default: false }) private support!: boolean
  @Prop({ default: false }) private button!: boolean
  @Prop({ default: false }) private showTitle!: boolean
  @Prop({ default: false }) private openOnClick!: boolean
  @Prop({ default: false }) private preventInitialHover!: boolean

  private tooltipOpen = false
  private prevent = false

  mounted (): void {
    this.prevent = this.preventInitialHover
  }

  closeTooltip (event: FocusEvent): void {
    const target = event.target as HTMLElement
    if (target && target.classList) {
      if (!target.classList.contains('is-link')) {
        this.tooltipOpen = false
      }
    } else {
      this.tooltipOpen = false
    }
  }
}
